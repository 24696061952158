import React from "react"
import Layout from "../Components/layout"

import { useStaticQuery, graphql } from "gatsby"

import Hero from "../Components/ssp/hero"
import ContentCards from "../Components/ssp/contentCards"
import InquireSection from "../Components/inquirelink"

import SEO from "../Components/SEO"


export default function Services() {
  const data = useStaticQuery(graphql`
    {
      directory: allContentJson {
        nodes {
          services {
            list {
              title
              image{
                src {
                  childImageSharp {
                    fixed(width: 80){
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
      banner: file(relativePath: { eq: "services/main/service_banner.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Services" description="Fastlink offers a wide variety of products that will cater all the consumer and enterprise's needs, wants, and requirements"></SEO>
      <Hero
        image={data.banner.childImageSharp.fluid}
        heading="Our Services"
        description="For over 19 years, Fastlink has been producing and delivering efficient solutions that addresses our clients' ICT Requirements. Each Solution that is designed is presented with its corresponding BOM, Proof of Value, and Proof of Concept."
      />
      <ContentCards heading="List of Services" data={data.directory.nodes[0].services.list} />

      <InquireSection />
    </Layout>
  )
}
